import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from 'styled-components';

const FormSubmitStyles = styled.div`

    display: flex;
    flex-direction: column;
    min-height: 35vh;
    padding: 3.5rem 0;

    h2 {
        padding-bottom: 1.5rem;
        font-weight: 300;
    }

    p {
        padding-bottom: 1rem;
    }

    .gatsby-image-wrapper {
        border-radius: 3px;
    }

`;

export default function ContactSubmit({ data }) {
    const submitImage = getImage(data.submitImage.portfolioImage.asset);
    return (
        <FormSubmitStyles>
            <h2>Thank you for your message!</h2>
            <p>I will respond as soon as possible. <Link to={'/'} className="highlight">Follow this link</Link> to return to the homepage.</p>
            <GatsbyImage image={submitImage} alt={data.submitImage.title} />
        </FormSubmitStyles>
    )
}

export const query = graphql`
    query {
        submitImage: sanityPortfolioImage(title: {eq: "Franny smiling in the sunshine"}) {
            title
            portfolioImage {
                asset {
                    gatsbyImageData (
                        placeholder: BLURRED
                        height: 450
                    )
                }
            }
        }
    }
`;